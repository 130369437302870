import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/Layout';
import Agreement from '../components/Agreement/Agreement';

const IndexPage = ({ data }) => (
	<Layout footerImg={data.logo_footer} footerEitImg={data.logo_eit_footer}>
		<section className="bg-white">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<Agreement />
					</div>
				</div>
			</div>
		</section>
	</Layout>
);

export const query = graphql`
	{
		logo_footer: file(relativePath: { eq: "logo_footer.png" }) {
			childImageSharp {
				fixed(width: 92, quality: 100) {
					...GatsbyImageSharpFixed_withWebp_tracedSVG
				}
			}
		}
		logo_eit_footer: file(relativePath: { eq: "eit.png" }) {
			childImageSharp {
				fixed(width: 200, quality: 100) {
					...GatsbyImageSharpFixed_withWebp_tracedSVG
				}
			}
		}
	}
`;

export default IndexPage;
